<template>
  <BlankLayout>
    <v-layout align-center justify-center>
      <div>
        <div style="text-align: center">
          <img
            src="@/assets/logo.png"
            width="200"
            style="padding-bottom: 20px"
          />
        </div>
        <div style="text-align: center">
          <span v-if="alert !== ''">
            {{ alert }}
          </span>
          <SwitchDevicePrompt
            v-else-if="switchDevicePrompt"
            @no="userDoesNotWantToLoginHere"
            @yes="userWantsToLoginHere"
          ></SwitchDevicePrompt>
          <v-progress-circular
            v-else
            indeterminate
            color="green"
          ></v-progress-circular>
          <br /><br />
          <a href="https://fair-votes.com/live">FairVotes LIVE</a>
        </div>
      </div>
    </v-layout>
  </BlankLayout>
</template>

<script>
import BlankLayout from '@/components/layouts/Blank.vue'
import handle_errors from '@/api/handle_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'
import beforeunload from '@/helpers/beforeunload.js'
import SwitchDevicePrompt from '@/components/SwitchDevicePrompt.vue'

async function login(access_token, login_secret = null) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/login/${access_token}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login_secret: login_secret,
      }),
    }
  )
}

export default {
  name: 'Login',

  // this.$route.params.access_token
  props: {
    access_token: String,
  },

  components: {
    BlankLayout,
    SwitchDevicePrompt,
  },

  data() {
    return {
      alert: '',
      switchDevicePrompt: false,
      login_secret: null,
    }
  },

  beforeRouteEnter: function (to, from, next) {
    login(to.params.access_token)
      .then(handle_token_refresh)
      .then(handle_errors)
      .then(data => {
        next(vm => {
          vm.$mystore.setEvent(data.data.event)
          vm.$mystore.setLoggedIn(true)

          beforeunload.enable()

          vm.$i18n.locale = vm.$mystore.state.event.language

          localStorage.setItem('token', data.data.token)

          vm.$router.replace({ name: 'Home' })
        })
      })
      .catch(response => {
        if (response.status === 409) {
          next(async vm => {
            let data = await response.json()

            vm.login_secret = data.data.login_secret

            vm.switchDevicePrompt = true
          })
        } else if (response.status === 403) {
          next(vm => {
            vm.$router.replace({
              name: 'TwoFactorAuthentication',
              params: { token: to.params.access_token },
            })
          })
        } else {
          next(vm => {
            vm.alert = vm.$t('views.login.unauthorized')
          })
        }

        console.error(response)
      })
  },

  methods: {
    userDoesNotWantToLoginHere() {
      this.alert = this.$t('components.switchDevicePrompt.doesNotWantToLogin')

      this.switchDevicePrompt = false
    },

    userWantsToLoginHere() {
      login(this.access_token, this.login_secret)
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(data => {
          this.$mystore.setEvent(data.data.event)
          this.$mystore.setLoggedIn(true)

          beforeunload.enable()

          this.$i18n.locale = this.$mystore.state.event.language

          localStorage.setItem('token', data.data.token)

          this.$router.replace({ name: 'Home' })

          this.switchDevicePrompt = false
        })
        .catch(async response => {
          if (response.status === 409) {
            let data = await response.json()

            this.login_secret = data.data.login_secret

            this.switchDevicePrompt = true
          } else if (response.status === 403) {
            this.$router.replace({
              name: 'TwoFactorAuthentication',
              params: { token: this.access_token },
            })
          } else {
            this.alert = this.$t('views.login.unauthorized')
          }

          console.error(response)
        })
    },
  },
}
</script>
